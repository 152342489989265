.dataimg-arrow-down {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-down.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-left-pink {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-left-pink.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-left {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-left.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-light-left {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-light-left.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-light-right {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-light-right.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-new-edition-blue {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-new-edition-blue.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-new-edition {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-new-edition.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-right-blue {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-right-blue.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-right-orange {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-right-orange.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-right-pink {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-right-pink.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-right-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-right-white.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-right-yellow {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-right-yellow.svg");
    background-repeat: no-repeat;
}

.dataimg-arrow-right {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/arrow-right.svg");
    background-repeat: no-repeat;
}

.dataimg-calendar {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/calendar.svg");
    background-repeat: no-repeat;
}

.dataimg-comments-pink {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/comments-pink.svg");
    background-repeat: no-repeat;
}

.dataimg-comments-shadow-hover {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/comments-shadow-hover.svg");
    background-repeat: no-repeat;
}

.dataimg-comments-shadow {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/comments-shadow.svg");
    background-repeat: no-repeat;
}

.dataimg-comments-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/comments-white.svg");
    background-repeat: no-repeat;
}

.dataimg-comments {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/comments.svg");
    background-repeat: no-repeat;
}

.dataimg-cross {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/cross.svg");
    background-repeat: no-repeat;
}

.dataimg-culture-color {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/culture-color.svg");
    background-repeat: no-repeat;
}

.dataimg-culture-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/culture-white.svg");
    background-repeat: no-repeat;
}

.dataimg-facebook-shadow-hover {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/facebook-shadow-hover.svg");
    background-repeat: no-repeat;
}

.dataimg-facebook-shadow {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/facebook-shadow.svg");
    background-repeat: no-repeat;
}

.dataimg-facebook.colors-facebook-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/facebook.colors-facebook-white.svg");
    background-repeat: no-repeat;
}

.dataimg-facebook {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/facebook.svg");
    background-repeat: no-repeat;
}

.dataimg-bublina-datum {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/bublina-datum.svg");
    background-repeat: no-repeat;
}

.dataimg-bublina-narodni-muzeum {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/bublina-narodni-muzeum.svg");
    background-repeat: no-repeat;
}

.dataimg-bublina-o-festivalu {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/bublina-o-festivalu.svg");
    background-repeat: no-repeat;
}

.dataimg-bublina-pozorovani-slunce {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/bublina-pozorovani-slunce.svg");
    background-repeat: no-repeat;
}

.dataimg-bublina-prohlidky-krytu {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/bublina-prohlidky-krytu.svg");
    background-repeat: no-repeat;
}

.dataimg-bublina-tombola {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/bublina-tombola.svg");
    background-repeat: no-repeat;
}

.dataimg-bublina-vareni {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/bublina-vareni.svg");
    background-repeat: no-repeat;
}

.dataimg-bublina-vstup-dospeli {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/bublina-vstup-dospeli.svg");
    background-repeat: no-repeat;
}

.dataimg-bublina-vstup-zdarma {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/bublina-vstup-zdarma.svg");
    background-repeat: no-repeat;
}

.dataimg-hvezda-2 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/hvezda-2.svg");
    background-repeat: no-repeat;
}

.dataimg-logo-abc {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/logo-abc.svg");
    background-repeat: no-repeat;
}

.dataimg-logo-festival {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/logo-festival.svg");
    background-repeat: no-repeat;
}

.dataimg-mouse-continue {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/mouse-continue.svg");
    background-repeat: no-repeat;
}

.dataimg-mrak-01 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/mrak-01.svg");
    background-repeat: no-repeat;
}

.dataimg-mrak-02 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/mrak-02.svg");
    background-repeat: no-repeat;
}

.dataimg-mrak-03 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/mrak-03.svg");
    background-repeat: no-repeat;
}

.dataimg-mrak-04 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/mrak-04.svg");
    background-repeat: no-repeat;
}

.dataimg-pozadi-mrak-bily {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/pozadi-mrak-bily.svg");
    background-repeat: no-repeat;
}

.dataimg-pozadi-mrak-modry {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/pozadi-mrak-modry.svg");
    background-repeat: no-repeat;
}

.dataimg-pozadi-mrak-ruzovy {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/pozadi-mrak-ruzovy.svg");
    background-repeat: no-repeat;
}

.dataimg-pozadi-pruhy {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/pozadi-pruhy.svg");
    background-repeat: no-repeat;
}

.dataimg-prsk-1 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/prsk-1.svg");
    background-repeat: no-repeat;
}

.dataimg-prsk-2 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/prsk-2.svg");
    background-repeat: no-repeat;
}

.dataimg-sipka-vlevo {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/sipka-vlevo.svg");
    background-repeat: no-repeat;
}

.dataimg-sipka-vpravo {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/sipka-vpravo.svg");
    background-repeat: no-repeat;
}

.dataimg-text-datum {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/text-datum.svg");
    background-repeat: no-repeat;
}

.dataimg-text-hlavni {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/text-hlavni.svg");
    background-repeat: no-repeat;
}

.dataimg-text-muzeum {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/text-muzeum.svg");
    background-repeat: no-repeat;
}

.dataimg-text-vek {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/text-vek.svg");
    background-repeat: no-repeat;
}

.dataimg-flash {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/flash.svg");
    background-repeat: no-repeat;
}

.dataimg-games-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/games-white.svg");
    background-repeat: no-repeat;
}

.dataimg-games {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/games.svg");
    background-repeat: no-repeat;
}

.dataimg-gift {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/gift.svg");
    background-repeat: no-repeat;
}

.dataimg-instagram {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/instagram.svg");
    background-repeat: no-repeat;
}

.dataimg-komiksy-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/komiksy-white.svg");
    background-repeat: no-repeat;
}

.dataimg-login-shadow {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/login-shadow.svg");
    background-repeat: no-repeat;
}

.dataimg-login {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/login.svg");
    background-repeat: no-repeat;
}

.dataimg-logo-grey {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/logo-grey.svg");
    background-repeat: no-repeat;
}

.dataimg-logo-panikluci {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/logo-panikluci.svg");
    background-repeat: no-repeat;
}

.dataimg-logo {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/logo.svg");
    background-repeat: no-repeat;
}

.dataimg-mourris {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/mourris.svg");
    background-repeat: no-repeat;
}

.dataimg-mourrison-color {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/mourrison-color.svg");
    background-repeat: no-repeat;
}

.dataimg-mourrison-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/mourrison-white.svg");
    background-repeat: no-repeat;
}

.dataimg-nature-color {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/nature-color.svg");
    background-repeat: no-repeat;
}

.dataimg-nature-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/nature-white.svg");
    background-repeat: no-repeat;
}

.dataimg-new-magazine {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/new-magazine.svg");
    background-repeat: no-repeat;
}

.dataimg-open-comics {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/open-comics.svg");
    background-repeat: no-repeat;
}

.dataimg-panikluci-stitek {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/panikluci-stitek.svg");
    background-repeat: no-repeat;
}

.dataimg-play-blue {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/play-blue.svg");
    background-repeat: no-repeat;
}

.dataimg-play-green {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/play-green.svg");
    background-repeat: no-repeat;
}

.dataimg-play-pink {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/play-pink.svg");
    background-repeat: no-repeat;
}

.dataimg-play-purple {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/play-purple.svg");
    background-repeat: no-repeat;
}

.dataimg-play {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/play.svg");
    background-repeat: no-repeat;
}

.dataimg-player-arrow {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/player-arrow.svg");
    background-repeat: no-repeat;
}

.dataimg-player-eye {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/player-eye.svg");
    background-repeat: no-repeat;
}

.dataimg-predplatne_ABC {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/predplatne_ABC.svg");
    background-repeat: no-repeat;
}

.dataimg-reader-black {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/reader-black.svg");
    background-repeat: no-repeat;
}

.dataimg-science-color {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/science-color.svg");
    background-repeat: no-repeat;
}

.dataimg-science-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/science-white.svg");
    background-repeat: no-repeat;
}

.dataimg-search-shadow {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/search-shadow.svg");
    background-repeat: no-repeat;
}

.dataimg-search {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/search.svg");
    background-repeat: no-repeat;
}

.dataimg-sipka-button {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/sipka-button.svg");
    background-repeat: no-repeat;
}

.dataimg-sipka-leva-panikluci {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/sipka-leva-panikluci.svg");
    background-repeat: no-repeat;
}

.dataimg-sipka-prava-panikluci {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/sipka-prava-panikluci.svg");
    background-repeat: no-repeat;
}

.dataimg-smile1 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile1.svg");
    background-repeat: no-repeat;
}

.dataimg-smile10 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile10.svg");
    background-repeat: no-repeat;
}

.dataimg-smile11 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile11.svg");
    background-repeat: no-repeat;
}

.dataimg-smile12 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile12.svg");
    background-repeat: no-repeat;
}

.dataimg-smile2 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile2.svg");
    background-repeat: no-repeat;
}

.dataimg-smile3 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile3.svg");
    background-repeat: no-repeat;
}

.dataimg-smile4 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile4.svg");
    background-repeat: no-repeat;
}

.dataimg-smile5 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile5.svg");
    background-repeat: no-repeat;
}

.dataimg-smile6 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile6.svg");
    background-repeat: no-repeat;
}

.dataimg-smile7 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile7.svg");
    background-repeat: no-repeat;
}

.dataimg-smile8 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile8.svg");
    background-repeat: no-repeat;
}

.dataimg-smile9 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/smile9.svg");
    background-repeat: no-repeat;
}

.dataimg-souteze-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/souteze-white.svg");
    background-repeat: no-repeat;
}

.dataimg-star-left {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/star-left.svg");
    background-repeat: no-repeat;
}

.dataimg-star-right {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/star-right.svg");
    background-repeat: no-repeat;
}

.dataimg-technic-color {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/technic-color.svg");
    background-repeat: no-repeat;
}

.dataimg-technic-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/technic-white.svg");
    background-repeat: no-repeat;
}

.dataimg-twitter-shadow-hover {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/twitter-shadow-hover.svg");
    background-repeat: no-repeat;
}

.dataimg-twitter-shadow {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/twitter-shadow.svg");
    background-repeat: no-repeat;
}

.dataimg-twitter {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/twitter.svg");
    background-repeat: no-repeat;
}

.dataimg-video-2 {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/video-2.svg");
    background-repeat: no-repeat;
}

.dataimg-video-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/video-white.svg");
    background-repeat: no-repeat;
}

.dataimg-vystrihovanky-white {
    background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/vystrihovanky-white.svg");
    background-repeat: no-repeat;
}
